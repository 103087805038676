
<mat-form-field appearance="fill">
  <mat-label>Layer Title</mat-label>
  <input matInput [formControl]="titleFC">
  <mat-error *ngIf="titleFC.invalid">Title is required</mat-error>
</mat-form-field>
<mat-dialog-content>
  <div *ngFor="let image of images; let i = index">
    <mat-form-field>
      <mat-label>Image value</mat-label>
      <input matInput value="{{image.title}}" [formControl]="images[i].fc">
      <mat-error *ngIf="images[i].fc.invalid">Image title is required</mat-error>
    </mat-form-field>
    <file-upload fullFileName="{{image.image}}" requiredFileType="image/png" [formControl]="images[i].fcImage"></file-upload>
    <button mat-mini-fab color="primary" class="remove-btn" (click)="removeImage(i)">
        <mat-icon>delete</mat-icon>
    </button>
    <mat-error *ngIf="images[i].fcImage.invalid">Image is required</mat-error>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="addImage()">Add Image</button><br>
  <button mat-flat-button color="primary" (click)="saveLayer()">Save Layer</button><br>
  <button mat-flat-button color="primary" (click)="cancel()">Cancel</button><br>
</div>
