
<div class="container">
  <h1>Minting Systems</h1>
  <p>
    <b>minting.systems</b> is your first partner in your NFT project.
  </p>
  <br>

  <h2>Case studies</h2>
  <p>
    You have a concert and you want to give your visitors possibility to mint your NFTs with 50% discount or even give them one NFT for free?<br>
    This scenario can easly be achived with <b>minting.systems</b><br>
    <br>
  </p>

</div>
