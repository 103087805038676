<mat-dialog-content>
  <h2 class="text-center m-2">Sign in</h2>
  <h4 class="text-black m-4">Choose your wallet Login Authentication</h4>

  <div class="mt-3">

    <button mat-flat-button color="accent" class="w-100 m-2" (click)="loginKaikas('8217')">Klaytn Cypress, mainnet (kaikas)</button>
    <br>
    <button mat-flat-button color="accent" class="w-100 m-2">Polygon mainnet (metamask)</button>
    <br>
<!--     <p>If you just want to play around:</p>
    <button mat-flat-button color="primary" class="w-auto">Klaytn Baobab, testnet (kaikas)</button>
    <br>
    <button mat-flat-button color="primary" class="w-auto">Polygon Mumbai, testnet (metamask)</button> -->
  </div>

</mat-dialog-content>
