import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { finalize, firstValueFrom, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../services/user.service";

@Component({
  selector: 'file-upload',
  templateUrl: "file-upload.component.html",
  styleUrls: ["file-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: FileUploadComponent
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {

    @Input()
    requiredFileType:string;

    @Input()
    fullFileName:string;

    @Input()
    value:string;

    onChange = (value:string) => {};
    onTouched = () => {};

    touched = false;
    disabled = false;

    fileName = '';
    //fullFileName?:string = undefined;
    uploadProgress?:number;
    uploadSub?: Subscription;

    constructor(
      private http: HttpClient,
      private userService:UserService
    ) {}

    writeValue(newValue: string): void {
      if (newValue != "")
        this.fullFileName = environment.apiUrl + "/api/image/get/" + newValue;
    }

    registerOnChange(onChange: any): void {
      this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
      this.onTouched = onTouched;
    }

    async onFileSelected(event:any) {
        const file:File = event.target.files[0];
        this.markAsTouched();
        if (file) {
            this.fileName = file.name;
            const formData = new FormData();
            formData.append("file", file);
            console.log(file);
            const ep = environment.apiUrl + "/api/image/upload";
            let userInfo = await firstValueFrom(this.userService.loginInfoObserver);
            const upload$ = this.http.post(ep, formData, {
                reportProgress: true,
                observe: 'events',
                headers: {"Authorization": "Bearer " + userInfo.token!}
            })
            .pipe(
                finalize(() => this.reset())
            );

            this.uploadSub = upload$.subscribe(event => {
              if (event.type == HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round(100 * (event.loaded / event.total!));
              }
              if (event.type == HttpEventType.Response) {
                let response:any = event.body;
                this.value = response.image;
                this.onChange(this.value);
                this.writeValue(this.value);
              }
            })
        }
    }

  cancelUpload() {
    this.uploadSub?.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = undefined;
    this.uploadSub = undefined;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
