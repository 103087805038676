<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport autoFocus="false"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <!-- <mat-toolbar>Menu</mat-toolbar> -->
    <mat-nav-list (click)="drawer.toggle()">
      <a mat-list-item routerLink="/" routerLinkActive="" [routerLinkActiveOptions]="{ exact: true }">Home</a>
      <a mat-list-item routerLink="/how" routerLinkActive="active">How does it work</a>
      <a mat-list-item routerLink="/pricing" routerLinkActive="active">Pricing</a>
      <a mat-list-item routerLink="/manage" routerLinkActive="active">Create project</a>
      <a mat-list-item routerLink="/manage" routerLinkActive="active">Manage project</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">

    <ms-header [drawer]="drawer"></ms-header>
    <div class="d-flex flex-column flex-grow-1" style="margin-top: 80px; margin-bottom: 2rem;">
      <router-outlet></router-outlet>
    </div>
    <ms-footer></ms-footer>

  </mat-sidenav-content>
</mat-sidenav-container>
