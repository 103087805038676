import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoginInfo, ProjectsInfo, UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  public loginInfo: Observable<LoginInfo>;
  public projects: Observable<ProjectsInfo[]>
  public loginInfoSubscription?:Subscription;

  constructor(
    private userService:UserService
  ) { }

  ngOnInit(): void {
    this.loginInfo = this.userService.loginInfoObserver;
    this.loginInfoSubscription = this.userService.loginInfoObserver.subscribe((li:LoginInfo)=>{
      console.log("loggedin: " + li.loggedIn);
      if (li.loggedIn == true) {
        this.projects = this.userService.getProjects(li.token!)
      }
    });
  }

  ngOnDestroy():void{
    this.loginInfoSubscription?.unsubscribe();
  }


}
