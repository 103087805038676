import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, firstValueFrom, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var Caver: any;

export interface LoginInfo {
  isConnected: boolean;
  address?: string;
  chain?: string;
  chainId?: string;
  lastError?: string;
  loggedIn: boolean;
  token?: string;
}

export interface ProjectsInfo {
  id: string,
  title: string,
  chain: string
}

export interface Project {
  id: string,
  title: string,
  chain: string
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
      private window: Window,
      private httpClient: HttpClient,
      private cookieService: CookieService
  ) {

  }

  private userLoginInfo = new ReplaySubject<LoginInfo>(1);


  public toWei(amount: number) {
    return Caver.utils.toWei(amount+"", "ether");
  }

  public fromWei(amount: number) {
    return Caver.utils.fromWei(amount)
  }

  get loginInfoObserver() {
    return this.userLoginInfo.asObservable();
  }

  get kaikas() {
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      return (this.window as any).klaytn;
    }
    return null;
  }

  logout() {
    let loginInfo:LoginInfo = {
      isConnected: false,
      loggedIn: false
    }
    this.userLoginInfo.next(loginInfo);
    this.cookieService.delete("login-chain");
    this.cookieService.delete("login-chain-id");
    this.cookieService.delete("login-token");
    this.cookieService.delete("login-address");
  }

  silentLogin() {
    /*
    this.cookieService.set("login-chain", "KLAYTN");
    this.cookieService.set("login-chain-id", chainId);
    this.cookieService.set("login-token", loginRet.token);
    this.cookieService.set("login-address", kaikas.selectedAddress);
    */
    let cookieToken = this.cookieService.get("login-token");
    if (cookieToken != null) {
      if (this.cookieService.get("login-chain") == "KLAYTN") {
        const chainId = this.cookieService.get("login-chain-id");
        const address = this.cookieService.get("login-address");
        this.loginKaikasProcedure(chainId, true, address, cookieToken);
      }
    }
  }

  async loginKaikas(chainId:string): Promise<void> {
    return this.loginKaikasProcedure(chainId, false);
  }

  private async loginKaikasProcedure(chainId:string, silent:boolean, address?:string, token?:string): Promise<void> {
    let kaikas = null;
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      kaikas = (this.window as any).klaytn;
    } else {
      let loginInfo:LoginInfo = {
        isConnected: false,
        loggedIn: false,
        lastError: "KAIKAS_NOT_INSTALLED"
      }
      this.userLoginInfo.next(loginInfo);
      return;
    }
    if (kaikas.networkVersion != chainId) {
      console.log("invalid network!");
      let loginInfo:LoginInfo = {
        isConnected: false,
        loggedIn: false,
        lastError: "KAIKAS_NOT_INSTALLED"
      }
      this.userLoginInfo.next(loginInfo);
      return;
    }

    try {
      const accounts = await kaikas.enable()
    } catch (error) {
      let loginInfo:LoginInfo = {
        isConnected: false,
        loggedIn: false,
        lastError: "KAIKAS_ERROR_CONNECTING"
      }
      this.userLoginInfo.next(loginInfo);
      return;
    }

    if (silent == true) {
      if (kaikas.selectedAddress != address) {
        this.logout();
      }
    }

    let loginInfo:LoginInfo = {
      address: kaikas.selectedAddress,
      isConnected: true,
      loggedIn: false,
      chain: "KLAYTN",
      chainId: chainId,
    }
    this.userLoginInfo.next(loginInfo);
    if (silent == true) {
      loginInfo = {
        address: kaikas.selectedAddress,
        isConnected: true,
        loggedIn: true,
        chain: "KLAYTN",
        chainId: chainId,
        token: token
      }
      this.userLoginInfo.next(loginInfo);
      return;
    }
    let sign = await this.signKaikas("login", kaikas.selectedAddress);
    let loginRet = await firstValueFrom(this.ajaxLogin(sign));
    if (loginRet.token) {
      loginInfo = {
        address: kaikas.selectedAddress,
        isConnected: true,
        loggedIn: true,
        chain: "KLAYTN",
        chainId: chainId,
        token: loginRet.token
      }
      this.userLoginInfo.next(loginInfo);
      this.cookieService.set("login-chain", "KLAYTN", undefined, "/");
      this.cookieService.set("login-chain-id", chainId, undefined, "/");
      this.cookieService.set("login-token", loginRet.token, undefined, "/");
      this.cookieService.set("login-address", kaikas.selectedAddress, undefined, "/");
    } else {
      loginInfo = {
        address: kaikas.selectedAddress,
        isConnected: true,
        loggedIn: false,
        chain: "KLAYTN",
        chainId: chainId,
        token: loginRet.error
      }
      this.userLoginInfo.next(loginInfo);
    }


  }

  public shortAddress(addr:string) {
    return addr.substr(0, 5) + "..." + addr.substr(addr.length-5, 5);
  }

  async signKaikas(message: string, address:string) {
      const msgToSign = {
        "address": address,
        "message": message
      };
      var messageJson = JSON.stringify(msgToSign);
      console.log("msg to sign ", msgToSign);
      let klaytn = (this.window as any).klaytn;
      let caver = new Caver(klaytn);

      var msgHex = caver.utils.stringToHex(messageJson);
			let signed = await caver.klay.sign(msgHex, address);
      return {message: messageJson, signature: signed};
    }

    private ajaxLogin(body:any): Observable<any> {
      const ep = environment.apiUrl + "/api/login";
      const options = {headers: {'Content-Type': 'application/json'}};
      return this.httpClient.post<string>(ep, JSON.stringify(body), options);
    }

    async saveProject(token:string, project: {id:string, title: any; chain: any; description: any; }) {
      const ep = environment.apiUrl + "/api/project/save";
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      console.log("ep", ep);
      console.log("options", options);
      console.log("project", JSON.stringify(project));
      let ret = await firstValueFrom(this.httpClient.post<string>(ep, JSON.stringify(project), options));
      return "DONE";
    }

    getProjects(token: string) {
      const ep = environment.apiUrl + "/api/project/all";
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      return this.httpClient.get<ProjectsInfo[]>(ep, options);
    }

    getProject(id: number, token:string) {
      const ep = environment.apiUrl + "/api/project/one/" + id;
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      return this.httpClient.get<Project>(ep, options);
    }
}
