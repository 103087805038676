import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface LayerInfo {
  id: string,
  title: string,
  numberOfImages: number,
  orderField: number
}


export interface LayerData {
  projectId: number,
  id: string,
  title: string,
  images: ImageData[]
}

export interface ImageData {
  id: string,
  traitValue: string,
  imageName: string
}

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  private layersInfo:Map<number, ReplaySubject<LayerInfo[]>> = new Map<number, ReplaySubject<any>>();

  constructor(
      private window: Window,
      private httpClient: HttpClient,
  ) {

  }

    async saveLayer(token:string, layerData:LayerData) {
      const ep = environment.apiUrl + "/api/layer/save";
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      let ret = await firstValueFrom(this.httpClient.post<string>(ep, JSON.stringify(layerData), options));
      return ret;
    }

    private getLayersInfoObservable(projectId:number) {
      if (this.layersInfo.get(projectId) == undefined) {
        this.layersInfo.set(projectId, new ReplaySubject(1));
      }
      return this.layersInfo.get(projectId)!;
    }

    getLayersInfo(projectId:number) {
      return this.getLayersInfoObservable(projectId).asObservable();
    }

    async getLayers(token: string, projectId: number) {
      const ep = environment.apiUrl + "/api/layer/all?project-id=" + projectId;
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      let ret = await firstValueFrom(this.httpClient.get<LayerInfo[]>(ep, options));
      this.getLayersInfoObservable(projectId).next(ret);
      return ret;
    }

    getLayer(id: number, token:string) {
      const ep = environment.apiUrl + "/api/layer/one/" + id;
      const options = {headers: {'Content-Type': 'application/json', "Authorization": "Bearer " + token}};
      return this.httpClient.get<LayerData>(ep, options);
    }
}
