<div class="container d-flex flex-column">
  <mat-form-field appearance="fill">
    <mat-label>Project Title</mat-label>
    <input matInput [formControl]="titleFC">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Blockchain</mat-label>
    <mat-select [formControl]="chainFC">
      <mat-option value="klaytn">Klaytn</mat-option>
      <mat-option value="matic">Matic</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <textarea matInput [formControl]="descriptionFC"></textarea>
  </mat-form-field>
  <div *ngIf="project!=null">
    <span>Layers</span>
    <mat-list>
      <mat-list-item *ngFor="let layer of layers; let i = index"> {{layer.title}} ({{layer.images}} images)
        <button mat-mini-fab color="primary" class="remove-btn"
          (click)="removeLayer(i)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-mini-fab color="primary" class="edit-btn"
          (click)="editLayer(layer.id)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="primary" class="edit-btn"
          (click)="editLayer(layer.id)">
            <mat-icon>
              drag_indicator
            </mat-icon>
        </button>
      </mat-list-item>
    </mat-list><br>
    <button mat-flat-button color="primary" (click)="editLayer('new')">Add Layer</button><br>
    <br>
    <span>Nft Sample</span><br>
    <div>
      <img style="max-width: 256px; max-height: 256px;" src="{{sampleImage}}">
    </div>

    <button mat-flat-button color="primary" (click)="regenerate()">Regenerate</button><br>
    <br>
    <button mat-flat-button color="primary" (click)="publish()">Publish*</button><br>
    <span>*After publish all fields will be locked.</span><br>
  </div>

  <button mat-flat-button color="primary" (click)="saveProject()" *ngIf="project==null">Create Project</button>
  <button mat-flat-button color="primary" (click)="saveProject()" *ngIf="project!=null">Save Project</button>
</div>
