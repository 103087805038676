import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, map, shareReplay, Observer, Subscription } from 'rxjs';
import { LoginWalletModalComponent } from '../components/login-wallet-modal/login-wallet-modal.component';
import { LoginInfo, UserService } from '../services/user.service';

@Component({
  selector: 'ms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private userObserver?: Subscription;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Input() drawer: any;
  public loginInfo: Observable<LoginInfo>;

  public address: string| undefined = "";

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userObserver = this.userService.loginInfoObserver.subscribe((userInfo)=>{
      console.log("userInfo", userInfo);
      this.address = userInfo.address;
    });
    this.loginInfo = this.userService.loginInfoObserver;
    this.userService.silentLogin();
  }

  logout() {
    this.userService.logout();
  }

  ngOnDestroy() {
    this.userObserver?.unsubscribe();
  }

  openLoginInDialog() {
    const dialogRef = this.dialog.open(LoginWalletModalComponent, {
      autoFocus: false,
      restoreFocus: false
    });
  }

  public shortAddress(addr:string | undefined) {
    if(addr == undefined || addr.length < 2) {
      return "";
    }
    return addr!.substr(0, 5) + "..." + addr!.substr(addr!.length-5, 5);
  }

}
