<div class="container">
  <h1>What you need</h1>
  <ol>
    <li>Collection of images in metadata</li>
    <li>Collection wiht metadata must be hosted on secure location</li>
    <li><b>minting.systems</b> partnership</li>
    <li>Website</li>
    <li>A little bit of luck</li>
  </ol>


  <h2>FAQ</h2>
  <p><b>Will i be the owner of NFT contract?</b><br>
    Yes<br>
    <br>
  </p>
</div>

