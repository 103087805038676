

<div class="container d-flex flex-column">
  <h1>Create new project</h1>
  <ng-template #loginScreen>
    <p>Before you can create/manage your project you need to sign up</p>
  </ng-template>

  <div class="container d-flex flex-column mt-5" *ngIf="((loginInfo|async)?.loggedIn == true) && (loginInfo|async); else loginScreen; let logi;">
    <div *ngFor="let project of projects|async; let i = index">
      {{i+1}}. <a routerLink="/project/{{project.id}}">{{project.title}}</a> on {{project.chain}}
    </div>
    <button mat-flat-button color="primary" style="width: 200px;" routerLink="/project/new">Create new project</button>
  </div>




