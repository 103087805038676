import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { IndexComponent } from './pages/index/index.component';
import { HowComponent } from './pages/how/how.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ManageComponent } from './pages/manage/manage.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { ProjectComponent } from './pages/project/project.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginWalletModalComponent } from './components/login-wallet-modal/login-wallet-modal.component';
import { LayerComponent } from './pages/project/layer/layer.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    NavigationComponent,
    IndexComponent,
    HowComponent,
    PricingComponent,
    ManageComponent,
    FooterComponent,
    ProjectComponent,
    LoginWalletModalComponent,
    LayerComponent,
    FileUploadComponent
   ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    HttpClientModule
  ],
  providers: [{ provide: Window, useValue: window }, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
