import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { LayerData, LayerService } from 'src/app/services/layer.service';
import { UserService } from 'src/app/services/user.service';

export interface DialogData {
  layerId: string;
  projectId: string;
}

@Component({
  selector: 'ms-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss']
})
export class LayerComponent implements OnInit {


  titleFC = new FormControl('', [Validators.required]);
  images:any[] = [
    {id:1, title:"Violet", image: "assets/sheeps/shape/1.png", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])},
    {id:2, title:"White", image: "assets/sheeps/shape/2.png", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])},
    {id:3, title:"Pink", image: "assets/sheeps/shape/3.png", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])},
    {id:4, title:"Blue", image: "assets/sheeps/shape/4.png", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])},
    {id:5, title:"Green", image: "assets/sheeps/shape/5.png", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])},
  ]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<LayerComponent>,
    private layerService: LayerService,
    private userService: UserService
  ) {

   }

  async ngOnInit(): Promise<void> {
    if (this.data.layerId == "new") {
      this.images = [];
    } else {
      this.images = [];
      let userInfo = await firstValueFrom(this.userService.loginInfoObserver);
      let dbLayer = await firstValueFrom(this.layerService.getLayer(Number(this.data.layerId), userInfo.token!));

      dbLayer.images.forEach(image => {
        let row:any = {id:image.id, fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])};
        row.fcImage.setValue(image.imageName);
        row.fc.setValue(image.traitValue);
        this.images.push(row);
      });

      this.titleFC.setValue(dbLayer.title);
    }
  }

  addImage() {
    this.images.push({id:"new", title:"", fcImage: new FormControl('', [Validators.required]), fc: new FormControl('', [Validators.required])});
  }

  removeImage(index: number) {
    this.images.splice(index, 1);
  }

  cancel() {
    this.dialogRef.close();
  }

  async saveLayer() {
    let valid = true;
    valid = valid && this.titleFC.valid;

    this.images.forEach(element => {
      valid = valid && element.fcImage.valid;
      valid = valid && element.fc.valid;
    });
    if (valid == false) return;

    let layerData:LayerData = {
      id: this.data.layerId,
      images: [],
      projectId: Number(this.data.projectId),
      title: this.titleFC.value
    }
    this.images.forEach(element => {
      layerData.images.push({
        id: element.id,
        imageName: element.fcImage.value,
        traitValue: element.fc.value
      });
    });
    console.log("data", layerData);
    let userInfo = await firstValueFrom(this.userService.loginInfoObserver);
    await this.layerService.saveLayer(userInfo.token!, layerData);
    this.dialogRef.close();
  }

}
