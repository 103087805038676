
<mat-toolbar color="primary" style="position:fixed; height: 56px; z-index: 980; box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);">

  <div *ngIf="!(isHandset$ | async)" class="d-flex flex-row col-12">
    <a class="header text-uppercase" mat-button routerLink="/" routerLinkActive="" [routerLinkActiveOptions]="{ exact: true }">Home</a>
    <a class="header text-uppercase" mat-button routerLink="/how" routerLinkActive="active">How does it work</a>
    <a class="header text-uppercase" mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
    <a class="header text-uppercase" mat-button routerLink="/manage" routerLinkActive="active">Create project</a>
    <a class="header text-uppercase" mat-button routerLink="/manage" routerLinkActive="active">Manage project</a>
    <span class="flex-grow-1"></span>
    <span class="align-self-center">{{ shortAddress(address) }}</span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template #loginbutton>
        <button mat-menu-item class="text-primary fw-bold fs-5" (click)="openLoginInDialog()">Sign in</button>
      </ng-template>
      <button *ngIf="((loginInfo | async )?.loggedIn == true) else loginbutton" mat-menu-item class="text-primary fw-bold fs-5" (click)="logout()">Sign Out</button>
      <!-- <button  mat-menu-item class="text-primary fw-bold fs-5" (click)="logout()">Sign Out</button> -->
    </mat-menu>
  </div>



  <div *ngIf="(isHandset$ | async)" class="d-flex flex-row align-items-center">
    <button  mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <p class="title"> Minting System</p>
  </div>


</mat-toolbar>
