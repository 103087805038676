import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, lastValueFrom, Subscription, take } from 'rxjs';
import { LayerInfo, LayerService } from 'src/app/services/layer.service';
import { Project, UserService } from 'src/app/services/user.service';
import { LayerComponent } from './layer/layer.component';

@Component({
  selector: 'ms-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  titleFC = new FormControl('', [Validators.required]);
  chainFC = new FormControl('', [Validators.required]);
  descriptionFC = new FormControl('', [Validators.required]);
  project: Project;
  layerInfoSubscription?: Subscription;
  loginInfoObserverSubscription?: Subscription;
  sampleImage:string="assets/4.png";
  allImages:any[] = ["4", "5", "6", "7", "8"];
  currentSampleImage:number = 0;
  layers:any[] = [];
  /*
    {id:1, title: "Background", images: 5},
    {id:2, title: "Shape", images: 5},
    {id:3, title: "Eye", images: 5},
    {id:4, title: "Mouth", images: 5},
    {id:5, title: "Hat", images: 5},
  ]
  */
  constructor(
    private userService:UserService,
    private route: ActivatedRoute,
    private layerService: LayerService,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    let projectId:string = this.route.snapshot.paramMap.get('id')!;
    this.userService.loginInfoObserver

    if (projectId != "new") {
      this.loginInfoObserverSubscription = this.userService.loginInfoObserver.subscribe((userInfo)=>{
        if (userInfo.loggedIn == false)
            return;
        this.userService.getProject(Number(projectId), userInfo.token!).pipe(take(1)).subscribe((project: Project)=> {
            this.project = project;
            this.titleFC.setValue(project.title);
            this.descriptionFC.setValue(project.description);
            this.chainFC.setValue(project.chain);
        });
        this.layerInfoSubscription?.unsubscribe();
        this.layerInfoSubscription = this.layerService.getLayersInfo(Number(projectId)).subscribe((layersInfo: LayerInfo[])=> {
          this.layers = [];
          layersInfo.forEach(layer => {
            this.layers.push({
              id: layer.id,
              title: layer.title,
              orderField: layer.orderField,
              images: layer.numberOfImages
            });
          });
        });
        this.layerService.getLayers(userInfo.token!, Number(projectId));
      });
    }
  }

  onNgDestory() {
      this.loginInfoObserverSubscription?.unsubscribe();
      this.layerInfoSubscription?.unsubscribe();
  }

  regenerate() {
    this.currentSampleImage++;
    if (this.currentSampleImage >= this.allImages.length)
      this.currentSampleImage = 0;
    this.sampleImage = "assets/" + this.allImages[this.currentSampleImage] + ".png";
  }

  removeLayer(index:number) {

  }
  publish() {

  }

  editLayer(layerId:string) {
    const dialogRef = this.dialog.open(LayerComponent, {
      data: {layerId: layerId, projectId: this.project.id},
      maxHeight: '90vh',
      minWidth: '1000px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result", result);
      //if(result != undefined) {
        this.refreshLayer();
      //}
    });
  }

  async refreshLayer() {
    let projectId:string = this.route.snapshot.paramMap.get('id')!;
    let userInfo = await firstValueFrom(this.userService.loginInfoObserver);
    this.layerService.getLayers(userInfo.token!, Number(projectId));
  }

  async saveProject() {
    let projectId:string = this.route.snapshot.paramMap.get('id')!;
    let userInfo = await firstValueFrom(this.userService.loginInfoObserver);
    this.userService.saveProject(userInfo.token!, {
      id: projectId,
      title: this.titleFC.value,
      chain: this.chainFC.value,
      description: this.descriptionFC.value
    });
  }
}
