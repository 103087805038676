import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'ms-login-wallet-modal',
  templateUrl: './login-wallet-modal.component.html',
  styleUrls: ['./login-wallet-modal.component.scss']
})
export class LoginWalletModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginWalletModalComponent>,
    private userService: UserService,) { }

  ngOnInit() {
  }

  loginKaikas(chainId: string){
    this.userService.loginKaikas(chainId).finally(() => {
      console.log("Ended"),
      this.dialogRef.close();
    });
  }

}
