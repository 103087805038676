import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HowComponent } from './pages/how/how.component';
import { IndexComponent } from './pages/index/index.component';
import { ManageComponent } from './pages/manage/manage.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ProjectComponent } from './pages/project/project.component';

const routes: Routes = [
  { path: "", component: IndexComponent},
  { path: "how", component: HowComponent},
  { path: "pricing", component: PricingComponent},
  { path: "manage", component: ManageComponent},
  { path: "project/:id", component: ProjectComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
