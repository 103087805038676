<div class="flex-shrink-0">


<div class="container d-flex flex-column ">

  <h1>Pricing</h1>
  <table class="table">
    <tr>
      <td></td>
      <td>FREE</td>
      <td>Standard</td>
      <td>Premium</td>
    </tr>
    <tr>
      <td>Commission</td>
      <td>15%</td>
      <td>0%</td>
      <td>0%</td>
    </tr>
    <tr>
      <td>Chains</td>
      <td>All</td>
      <td>All</td>
      <td>All</td>
    </tr>
    <tr>
      <td>Token security</td>
      <td>via proxy</td>
      <td>via proxy</td>
      <td>via proxy</td>
    </tr>
    <tr>
      <td>Whitelisting presale</td>
      <td>yes</td>
      <td>yes</td>
      <td>yes</td>
    </tr>
    <tr>
      <td>Progressive pricing</td>
      <td>yes</td>
      <td>yes</td>
      <td>yes</td>
    </tr>
    <tr>
      <td>Currency*</td>
      <td>Chain base</td>
      <td>Chain base</td>
      <td>Any</td>
    </tr>
    <tr>
      <td>Support</td>
      <td>via email</td>
      <td>via email</td>
      <td>high priority</td>
    </tr>
    <tr>
      <td>Price</td>
      <td>FREE</td>
      <td>2.000 USD</td>
      <td>6.000 USD</td>
    </tr>
  </table>
  <br><br>
  <p><b>Enterprise solution</b> Contact us if you need custom solution for your project.</p>
  <p>Currency: in Premium package you can choose any currency/token available on selected blockchain.</p>
  <p>Chains: we support polygon, ethereum, chronos, klaytn, smart chain.</p>

</div>
</div>
